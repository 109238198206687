import * as React from "react";

export interface IExtrenalLinkProps {
  href: string;
  label: string;
}

export default function ExtrenalLink({ href, label }: IExtrenalLinkProps) {
  return (
    <a className="external-link" href={href} target="_blank" rel="noreferrer noopener">
      {label}
    </a>
  );
}
