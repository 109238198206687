import { map } from "lodash";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { TReference } from "../recommendation/Notes";

const References: FC<{ references: TReference[] }> = ({ references }) => {
  const { t } = useTranslation();
  return (
    <div className="p-3">
      <h3 className="font-semibold text-blue-400">{t("labels.references")}</h3>
      {map(references, (r) => (
        <div key={r["@id"]}>
          <span className="font-semibold">{r["@id"]}</span>
          {": "}
          <span className="text-sm">{r.value}</span>
        </div>
      ))}
    </div>
  );
};

export default References;
