import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import GuidelineLink from "../common/GuidelineLink";
import RecommendationSnippet from "./RecommendationSnippet";

const RecommendationItem = ({ recommendation }) => {
  const { guideline, recommendation: text } = recommendation;
  return (
    <div className="recommendation-item">
      <Link className="text-black block p-3" to={`/recommendation/${recommendation["@id"]}`}>
        <span className="block text-sm font-raleway">
          {guideline && <GuidelineLink guideline={guideline} />}
          <RecommendationSnippet text={text} />
        </span>
      </Link>
    </div>
  );
};

RecommendationItem.propTypes = {
  recommendation: PropTypes.shape({
    "@id": PropTypes.string.isRequired,
    recommendation: PropTypes.string.isRequired,
    ages: PropTypes.arrayOf(PropTypes.string).isRequired,
    gradeCertaintyOfEvidence: PropTypes.string,
    gradeCertaintyOfEvidenceLabel: PropTypes.string,
    gradeStrength: PropTypes.string,
  }).isRequired,
};

export default RecommendationItem;
