import React, { useCallback, useMemo } from "react";
import _ from "lodash";
import { Link, withRouter } from "react-router-dom";
import logoSmall from "../assets/who-logo-small.svg";
import logo from "../assets/who-logo.svg";
import home from "../assets/home.svg";

import HamburgerMenu from "./common/HamburgerMenu";
import { useTranslation } from "react-i18next";
import { inIframe } from "../lib/helpers";

const Header = ({ location }) => {
  const { t } = useTranslation();

  const activeRoute = useMemo(
    () => _.first(_.split(_.trimStart(location.pathname, "/"), "/")),
    [location]
  );

  const navLinkClasses = useCallback(
    (link) => {
      return `top-nav-link text-blue-800 mr-4 py-1 ${activeRoute === link ? "active" : null}`;
    },
    [activeRoute]
  );

  return (
    <header className="z-10 relative bg-white h-12 shadow-md px-2 sm:h-15 flex flex-row items-center">
      <div className="flex-grow flex flex-row items-center">
        {location.pathname !== "/" && <Link to="/" className="mobile-back-link block sm:hidden" />}
        {inIframe() ? (
          <Link to="/" className="flex flex-row items-center">
            <img src={home} className="h-8 inline-block" alt="home" />
            <span className="ml-2 text-xl tailwind-hidden sm:inline-block">Home</span>
          </Link>
        ) : (
          <>
            <Link to="/">
              <img src={logo} className="h-12 tailwind-hidden sm:block" alt="logo" />
              <img src={logoSmall} className="h-8 block sm:hidden" alt="logo" />
            </Link>
            <span className="etb-guidelines-header inline-block text-xl">
              {t("app.recommendation.database")}
            </span>
          </>
        )}
      </div>
      <div className="flex flex-row items-center">
        <nav className="mr-12 tailwind-hidden md:block">
          <Link className={navLinkClasses("grid")} to="/grid">
            {t("recommendations.grid")}
          </Link>
          <Link className={navLinkClasses("recommendations")} to="/recommendations">
            {t("recommendations.list")}
          </Link>
        </nav>
        <HamburgerMenu />
      </div>
    </header>
  );
};

Header.propTypes = {};

export default withRouter(Header);
