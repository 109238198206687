import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import Card from "../common/Card";

const PageLayout = ({ children }) => {
  const { t } = useTranslation();
  return (
    <div className="bg-purple-500 overflow-hidden h-full page-content font-catamaran flex flex-col sm:flex-row">
      <div className="tailwind-hidden sm:block my-5 mx-3">
        <button
          onClick={() => window.history.back()}
          className="
            btn-page-back
            block
            rounded rounded-full py-2 px-8 bg-yellow-400 text-white hover:opacity-75 border 
            border-yellow-600"
        >
          {t("actions.back")}
        </button>
      </div>
      <div className="px-2 mt-2 sm:mt-5 flex-grow max-w-screen-lg md:mx-auto mb-2">
        <Card>{children}</Card>
      </div>
    </div>
  );
};

PageLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PageLayout;
