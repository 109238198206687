import PropTypes from "prop-types";
import React, { useMemo } from "react";
import { isEmpty } from "lodash";

const RMTPill = ({ displayMode, column, colorMap, highlightedRecommendation }) => {
  const columnContent = column.count;
  const emptyHighlightedRec = useMemo(
    () => isEmpty(highlightedRecommendation),
    [highlightedRecommendation]
  );

  const stringified = useMemo(() => JSON.stringify(column), [column]);
  const recIds = column.recommendations;
  const highlightPill = useMemo(
    () => recIds.includes(highlightedRecommendation),
    [recIds, highlightedRecommendation]
  );

  const bgColor = useMemo(
    () =>
      displayMode === "heatMap"
        ? `#${colorMap[columnContent - 1]}`
        : highlightPill
        ? "#FFBD29"
        : "#518AAF",
    [displayMode, colorMap, highlightPill, columnContent]
  );

  const isEmptyContent = useMemo(() => !columnContent || columnContent === 0, [columnContent]);
  const mutePill = useMemo(
    () => !emptyHighlightedRec && !highlightPill,
    [emptyHighlightedRec, highlightPill]
  );
  const pillClassName = useMemo(
    () => `
    ${columnContent > 0 ? "cursor-pointer" : "cursor-auto"}
    ${mutePill ? "mute" : ""}
  `,
    [columnContent, mutePill]
  );
  const pillStyles = useMemo(
    () => ({
      backgroundColor:
        columnContent === "not applicable"
          ? "#ADC2C5"
          : isEmptyContent
          ? "rgba(255, 255, 255, 0.5)"
          : bgColor,
    }),
    [bgColor, isEmptyContent, columnContent]
  );

  return (
    <div className={`rmt-pill ${pillClassName}`} style={pillStyles} data-column={stringified}>
      {!isEmptyContent && columnContent}
    </div>
  );
};

RMTPill.propTypes = {
  displayMode: PropTypes.oneOf(["normal", "heatMap"]).isRequired,
  column: PropTypes.shape({
    count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    recommendations: PropTypes.array,
  }).isRequired,
  colorMap: PropTypes.arrayOf(PropTypes.string).isRequired,
  highlightedRecommendation: PropTypes.string.isRequired,
};

export default RMTPill;
