import { DynamicHeightContainer } from "@evidenceprime/react-components";
import _ from "lodash";
import React, { FC, useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  TRecommendationGroup,
  TRecommendationsSubmodule,
  TRecommendationType,
} from "../../lib/types";
import LargeSpinner from "../common/LargeSpinner";
import RecommendationItem from "./RecommendationItem";

interface IRecommendationsList {
  recommendations: TRecommendationType[];
}

export const RecommendationsList: FC<IRecommendationsList> = ({ recommendations }) => {
  return (
    <ul className="recommendation-list">
      {_.map(recommendations, (recommendation, idx) => (
        <li key={`${idx}-${recommendation["@id"]}`} className="mt-2">
          <RecommendationItem recommendation={recommendation} />
        </li>
      ))}
    </ul>
  );
};

interface IRecommendationSubgroup {
  recommendationSubgroup: any;
  expandedSubgroups: string[];
  groupsDispatch: (arg: object) => object;
  searchQuery: string;
}

const RecommendationSubgroup: FC<IRecommendationSubgroup> = ({
  expandedSubgroups,
  groupsDispatch,
  recommendationSubgroup,
  searchQuery,
}) => {
  const { t } = useTranslation();
  const { name: groupName, recommendations } = recommendationSubgroup;
  const groupExpanded = _.includes(expandedSubgroups, groupName);
  const toggleGroup = useCallback(
    () => groupsDispatch({ type: "toggleSubgroup", groupName }),
    [groupName, groupsDispatch]
  );

  useEffect(() => {
    const shouldExpand =
      !_.isEmpty(searchQuery) && _.isEmpty(expandedSubgroups) && recommendations.length > 0;

    if (shouldExpand) {
      groupsDispatch({ type: "toggleSubgroup", groupName });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // deps array left empty so it will fire only once

  return (
    <div className={`ml-2 px-3 pb-3 recommendations-subgroup ${groupName}`}>
      <div
        className={`relative py-1 cursor-pointer expand-toggle text-blue-1300 ${
          groupExpanded ? "expanded" : "collapsed"
        }`}
        onClick={toggleGroup}
      >
        <span className="inline-block ml-3">
          {t(`tabs.${groupName}`)}{" "}
          <span className="inline-block ml-2 text-gray-300">{recommendations.length}</span>
        </span>
      </div>
      {groupExpanded && <RecommendationsList recommendations={recommendations} key={groupName} />}
    </div>
  );
};

interface IRecommendationGroup {
  recommendationsGroup: TRecommendationGroup;
  groupsState: {
    expandedGroups: string[];
    expandedSubgroups: string[];
  };
  groupsDispatch: (arg: object) => object;
  searchQuery: string;
}

const RecommendationGroup: FC<IRecommendationGroup> = ({
  recommendationsGroup,
  groupsState,
  groupsDispatch,
  searchQuery,
}) => {
  const { t } = useTranslation();
  const { name: groupName } = recommendationsGroup;
  const { expandedGroups, expandedSubgroups } = groupsState;
  const groupExpanded = _.includes(expandedGroups, groupName);
  const toggleGroup = useCallback(
    () => groupsDispatch({ type: "toggleGroup", groupName }),
    [groupName, groupsDispatch]
  );

  const moduleRecommendationsCount = useMemo(
    () =>
      _.reduce(
        recommendationsGroup.submodules,
        (count, submodule) => {
          return (count += submodule.recommendations.length);
        },
        0
      ),
    [recommendationsGroup]
  );

  useEffect(() => {
    const shouldExpand =
      !_.isEmpty(searchQuery) && _.isEmpty(expandedGroups) && moduleRecommendationsCount > 0;

    if (shouldExpand) {
      groupsDispatch({ type: "toggleGroup", groupName });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // deps array left empty so it will fire only once

  return (
    <DynamicHeightContainer className={`expandable-recommendation-group shadow mb-4 ${groupName}`}>
      <div
        className={`ml-3 relative p-3 cursor-pointer expand-toggle text-blue-1300 ${
          groupExpanded ? "expanded" : "collapsed"
        }`}
        onClick={toggleGroup}
      >
        <span className="inline-block ml-2">
          {t(`tabs.${recommendationsGroup.name}`)}{" "}
          <span className="inline-block ml-2 text-gray-200">{moduleRecommendationsCount}</span>
        </span>
      </div>
      {groupExpanded && recommendationsGroup.submodules.length > 1 ? (
        _.map(recommendationsGroup.submodules, (recommendationSubgroup) => {
          return (
            <RecommendationSubgroup
              key={recommendationSubgroup.name}
              groupsDispatch={groupsDispatch}
              expandedSubgroups={expandedSubgroups}
              recommendationSubgroup={recommendationSubgroup}
              searchQuery={searchQuery}
            />
          );
        })
      ) : groupExpanded && !_.isEmpty(recommendationsGroup.submodules) ? (
        <div className={`ml-2 px-3 pb-3 recommendations-subgroup ${groupName}`}>
          <RecommendationsList
            recommendations={
              (_.first(recommendationsGroup.submodules) as TRecommendationsSubmodule)
                .recommendations
            }
          />
        </div>
      ) : null}
    </DynamicHeightContainer>
  );
};

interface IRecommendationGroupsList {
  isLoading: boolean;
  recommendationGroups: TRecommendationGroup[];
  groupsState: {
    expandedGroups: string[];
    expandedSubgroups: string[];
  };
  groupsDispatch: (arg: object) => object;
  searchQuery: string;
}

const RecommendationGroupsList: FC<IRecommendationGroupsList> = ({
  isLoading,
  recommendationGroups,
  groupsState,
  groupsDispatch,
  searchQuery,
}) => {
  return (
    <div>
      {isLoading ? (
        <LargeSpinner />
      ) : (
        <div className="recommendation-list-container max-w-screen-lg pr-2">
          {_.map(recommendationGroups, (recommendationsGroup) => (
            <RecommendationGroup
              searchQuery={searchQuery}
              key={recommendationsGroup.name}
              groupsState={groupsState}
              groupsDispatch={groupsDispatch}
              recommendationsGroup={recommendationsGroup}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default RecommendationGroupsList;
