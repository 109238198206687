import _ from "lodash";
import PropTypes from "prop-types";
import React, { createRef } from "react";
import ReactTooltip from "react-tooltip";
import {
  guidelineShape,
  interventionShape,
  noteShape,
  populationShape,
  questionShape,
  referenceShape,
} from "../../lib/data_shapes";
import QuestionsData from "../common/QuestionsData";
import EtDPrintout from "./EtDPrintout";
import ISof from "./ISof";
import Remarks from "../common/Remarks";
import RecommendationHeader from "./RecommendationHeader";
import SourceOfRecommendation from "./SourceOfRecommendation";
import Notes from "./Notes";

const Recommendation = ({ document }) => {
  const {
    additionalEvidenceTableUrl,
    coexistingCondition,
    etdModified,
    etdPrintout,
    evidenceProfilePagesRangeGuideline,
    evidenceProfilePagesRangePDF,
    evidenceTableLink,
    evidenceToDecisionPagesRangeGuideline,
    evidenceToDecisionPagesRangePDF,
    evidenceToDecisionUrl,
    guideline,
    intervention,
    isofData,
    note,
    population,
    question,
    recommendation,
    recommendationIntent,
    reference,
    sofSupplemented,
    uniqueRecommendationCode,
  } = document;

  return (
    <>
      <RecommendationHeader
        gradeStrength={_.first(question).gradeStrength}
        gradeCertaintyOfEvidence={_.first(question).gradeCertaintyOfEvidence}
        gradeCertaintyOfEvidenceLabel={_.first(question).gradeCertaintyOfEvidenceLabel}
        intents={recommendationIntent}
        recommendation={recommendation}
        uniqueRecommendationCode={uniqueRecommendationCode}
      />
      <div className="separator my-3" />
      <Remarks remarks={recommendation.remarks} />
      <QuestionsData
        coexistingCondition={coexistingCondition}
        evidenceProfilePagesRangeGuideline={evidenceProfilePagesRangeGuideline}
        evidenceProfilePagesRangePDF={evidenceProfilePagesRangePDF}
        evidenceTableLink={evidenceTableLink}
        evidenceToDecisionPagesRangeGuideline={evidenceToDecisionPagesRangeGuideline}
        evidenceToDecisionPagesRangePDF={evidenceToDecisionPagesRangePDF}
        evidenceToDecisionUrl={evidenceToDecisionUrl}
        interventions={intervention}
        populations={population}
        questions={question}
        guidelineData={guideline}
      />
      <ISof
        isofData={isofData}
        toggleRef={createRef()}
        sofSupplemented={sofSupplemented}
        additionalEvidenceTableUrl={additionalEvidenceTableUrl}
      />
      <EtDPrintout etdModified={etdModified} etdPrintout={etdPrintout} toggleRef={createRef()} />
      <SourceOfRecommendation guideline={guideline} />
      <Notes
        notes={note}
        references={reference}
        toggleRef={createRef()}
        conclusions={recommendation.conclusions}
      />
      <ReactTooltip
        className="tooltip-container"
        type="dark"
        effect="float"
        backgroundColor="#031B49"
      />
    </>
  );
};

Recommendation.propTypes = {
  document: PropTypes.shape({
    guideline: guidelineShape,
    interventions: PropTypes.arrayOf(interventionShape),
    populations: PropTypes.arrayOf(populationShape),
    questions: PropTypes.arrayOf(questionShape),
    notes: PropTypes.arrayOf(noteShape),
    references: PropTypes.arrayOf(referenceShape),
  }).isRequired,
};

export default Recommendation;
