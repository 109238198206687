import _ from "lodash";
import { t } from "../i18n";
import FileSaver from "file-saver";
import { Reducer, useReducer, useRef, useEffect, EffectCallback, DependencyList } from "react";
import { TRecommendationType } from "./types";
import { TFunction } from "i18next";

export const isDebugMode = () => {
  // eslint-disable-next-line no-undef
  return process.env.NODE_ENV === "development" || process.env.NODE_ENV === "test";
};

export const isTestEnv = () => {
  // TODO change hostname to correct when test enviroment is prepared
  return window.location.hostname === "who-tb.test.evidenceprime.com";
};

// TODO configure later
// export const sendReportToSentry = error => Raven.captureException(error);
export function useSetState<U>(initialState: U) {
  const reducer = (state: U, toMerge: Partial<U> | ((state: U) => U)): U =>
    _.isFunction(toMerge) ? toMerge(state) : { ...state, ...toMerge };
  return useReducer<Reducer<U, Partial<U> | ((state: U) => U)>>(reducer, initialState);
}

export function useDidUpdateEffect(fn: EffectCallback, depsList?: DependencyList) {
  const didMountRef = useRef(false);

  useEffect(() => {
    if (didMountRef.current) fn();
    else didMountRef.current = true;
  }, [fn, ...(depsList ?? [])]);  // eslint-disable-line react-hooks/exhaustive-deps
}

export function stripHtml(html: string): string {
  const tmp = document.createElement("div");
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || "";
}

export const isValidLink = (maybeUrl: string): boolean => {
  try {
    const url = new URL(maybeUrl);
    return _.includes(["http:", "https:"], url.protocol);
  } catch (_ignored) {
    return false;
  }
};

const EXPORT_KEY_MAPPING: { [key: string]: string } = {
  recommendationText: "recommendation",
  guidelineCitation: "fullReferenceCitation",
  gradeStrength: "gradeStrength",
  directionOfTheRecommendation: "recommendationDirection",
  ages: "populationAge",
  population: "population",
  intervention: "intervention",
  recommendationIntent: "recommendationIntent",
  recommendationFormality: "recommendationType",
  guidelineTitle: "title",
  guidelinePublicationDate: "publicationDate",
  guidelineSourceDocumentLink: "linkToSourceDocument",
  guidelineWhoWorldRegion: "whoRegion",
  guidelineLatestLiteratureSearchDate: "latestDateOfLiteratureSearch",
  guidelineGradingEvidenceMethod: "methodOfGradingEvidence",
  guidelineField: "focus",
};

export const keysToSkip = [
  "@id",
  "displayId",
  "shortTitle",
  "title",
  "agreeScore",
  "intervention",
  "population",
  "guidelineNoId",
];
export const translatableKeys = [
  "module",
  "subModule",
  "version",
  "gradeStrength",
  "gradeCertaintyOfEvidence",
  "recommendationDirection",
];

const getInterventionOrPopulation = (
  entities: { [key: string]: { [key: string]: string } },
  key: string
) =>
  _.map(entities, (entity) =>
    _.upperFirst(entity[key === "intervention" ? "name" : "description"])
  ).join(", ");

export const getFormattedValueFromGuideline = (t: TFunction, key: string, value: any) => {
  if (key === "gradeCertaintyOfEvidenceLabel") {
    return value === "certainty_of_evidence" ? t("labels.certainty_of_evidence") : value;
  }

  if (_.includes(["intervention", "population"], key))
    return getInterventionOrPopulation(value, key);

  if (key === "recommendationIntent")
    return value.map((v: string) => t(`filters.${key}.${v}`)).join(", ");

  if (
    _.includes(
      ["publicationDate", "latestLiteratureSearchDate", "latestDateOfLiteratureSearch"],
      key
    )
  ) {
    if (_.isEmpty(value)) return "";
    return value !== "not_reported"
      ? new Date(value).toISOString().slice(0, 10)
      : t(`recommendation.${value}`);
  }

  if (key === "country") {
    return value.join(", ");
  }

  if (_.includes(["methodOfGradingEvidence", "gradingEvidenceMethod"], key)) {
    if (_.includes(["notGraded", "grade"], value)) {
      return t(`recommendation.fieldValues.${key}.${value}`);
    } else {
      return value;
    }
  }

  if (_.includes(translatableKeys, key)) {
    return _.includes(["module", "subModule"], key)
      ? t(`tabs.${value}`)
      : t(`recommendation.fieldValues.${key}.${value}`);
  }

  if (_.isArray(value)) {
    return key === "source"
      ? value.join(", ")
      : value.map((v) => t(`recommendation.fieldValues.${key}.${v}`)).join(", ");
  }
  if (
    _.isBoolean(value) ||
    value === "no" ||
    value === "false" ||
    value === "yes" ||
    value === "true"
  ) {
    const valueKey = value || value === "yes" || value === "true" ? "yes" : "no";
    return t(`recommendation.fieldValues.${key}.${valueKey}`);
  }
  if (isValidLink(value)) {
    return `<a href="${value}" target="_blank" rel="noopener noreferer">${value}</a>`;
  }
  if (value === "notReported") {
    return t(`recommendation.not_reported`);
  }
  if (key === "recommendationType") {
    return !_.isEmpty(value) ? t(`filters.recommendationFormality.${value}`) : "";
  }
  return (value || "").toString();
};

export const getGuidelineValues = (t: TFunction, guideline: any) => {
  return _.reduce(
    guideline,
    (acc, value, key) => {
      return keysToSkip.indexOf(key) !== -1 || _.isEmpty(value?.toString())
        ? acc
        : acc.concat({
            //@ts-ignore
            label: t(`recommendation.${key}`),
            value: getFormattedValueFromGuideline(t, key, value),
          });
    },
    []
  );
};

export const exportRecommendationsToCsv = (
  recommendations: TRecommendationType[],
  fields: string[]
): void => {
  const recommendationsArray: { [key: string]: any } = _.map(recommendations, (recommendation) => {
    //@ts-ignore
    const recommendationWithNewKeys = _.mapKeys(
      recommendation,
      (_, key) => EXPORT_KEY_MAPPING[key] ?? key
    );

    const recommendationWithParsedValues = _.reduce(
      _.pick(recommendationWithNewKeys, fields),
      (acc: { [key: string]: any }, value, key) => {
        acc[key] = stripHtml(getFormattedValueFromGuideline(t, key, value));
        return acc;
      },
      {}
    );
    return recommendationWithParsedValues;
  });

  let csv = _.map(fields, (field) => `"${t(`labels.${field}`)}"`).join(",") + "\n";
  csv += _.reduce(
    recommendationsArray,
    (rows: string[], recommendation) => {
      //@ts-ignore
      const recs = _.map(
        fields,
        (field) =>
          `"${(recommendation[field] || "").replace(/"/g, "'").replace(/\s/g, " ").trim()}"`
      ).join(",");
      rows.push(recs);
      return rows;
    },
    []
  ).join("\n");

  var blob = new Blob([csv], { type: "text/csv;charset=utf-8" });
  FileSaver.saveAs(blob, "export.csv");
};
