export const twitterLink = "";
export const glossaryLink = "https://gdt.gradepro.org/app/handbook/handbook.html#h.buaodtl66dyx";
export const getItGlossary = "https://getitglossary.org/";
export const showBanner = false;
export const FIELDS_TO_EXPORT = [
  "title", // guideline title
  "fullReferenceCitation", // citation of the guideline the recommendation comes from
  "linkToSourceDocument",
  "gradeCertaintyOfEvidence",
  "gradeStrength",
  "recommendation",
  "recommendationDirection",
  "populationAge",
  "focus",
  "publicationDate",
  "guidelineLatestLiteratureSearchDate",
  "intervention",
  "population",
  "module",
  "subModule",
];
