import * as React from "react";
import { useTranslation } from "react-i18next";
import ExternalLink from "../common/ExternalLink";
import _ from "lodash";

export interface IRangedLinkRowProps {
  label: string;
  link: string;
  pages?: string;
  pdfPages?: string;
}

export default function RangedLinkRow({ label, link, pages, pdfPages }: IRangedLinkRowProps) {
  const { t } = useTranslation();
  return link && pages && pdfPages
    ? {
        label,
        value: (
          <ExternalLink
            href={`${link}#page=${pdfPages}`}
            label={t("labels.see_pages", {
              pages: pages,
            })}
          />
        ),
      }
    : !_.isEmpty(link)
    ? {
        label,
        value: <ExternalLink href={link} label={link} />,
      }
    : null;
}
