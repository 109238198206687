import React from "react";
import { chain } from "lodash";
import { useTranslation } from "react-i18next";

const Intents = ({ intents }) => {
  const { t } = useTranslation();
  return (
    <div className="mt-8 mb-3">
      {t("labels.module")}
      {": "}
      <span className="font-semibold">
        {chain(intents)
          .without("all")
          .map((intent) => t(`tabs.${intent}`))
          .join(", ")
          .value()}
      </span>
    </div>
  );
};

Intents.propTypes = {};

export default Intents;
