import { reduce } from 'lodash';
import Parsimmon from 'parsimmon';

function skipWhitespace<T>(p: Parsimmon.Parser<T>): Parsimmon.Parser<T> {
  return p.skip(Parsimmon.optWhitespace);
}

const pageNumber = skipWhitespace(Parsimmon.regex(/[0-9]+/)).map(parseInt);

const pageRange = Parsimmon.seq(pageNumber.skip(skipWhitespace(Parsimmon.string('-'))), pageNumber)
  .map(([p1, p2]) => Math.min(p1, p2));

const pageExpr = skipWhitespace(pageRange.or(pageNumber));

const pageParser = Parsimmon.sepBy(pageExpr, skipWhitespace(Parsimmon.string(',')))
  .map(result => reduce(result, (a: number, b: number) => Math.min(a, b)));

export function parsePage(pageStr: string | undefined): number | undefined {
  if (!pageStr) return undefined;
  try {
    return pageParser.tryParse(pageStr.trim());
  } catch {
    return undefined;
  }
}
