import _ from "lodash";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import CustomTable from "../common/CustomTable";
import ExpandableCard from "../common/ExpandableCard";
import References from "./References";

export type TReference = {
  "@id": number;
  value?: string;
};

type TNote = {
  "@id": string;
  reference: TReference[];
  value: string;
};

interface INotes {
  notes?: TNote[];
  references?: TReference[];
  conclusions: {
    sections: {
      [key: string]: {
        content: string;
      };
    };
    sectionsOrder: string[];
  };
  toggleRef: HTMLDivElement;
}

const Notes: FC<INotes> = ({ notes, references, conclusions, toggleRef }) => {
  const { t } = useTranslation();
  const notesRows = _.chain(notes)
    .groupBy("@type")
    .map((n) =>
      _.first(n)
        ? {
            // @ts-ignore
            label: t(`labels.${_.snakeCase(_.first(n)["@type"])}`),
            value: _.map(n, (v, idx) => (
              <div className="mb-2" key={idx} dangerouslySetInnerHTML={{ __html: v.value }} />
            )),
          }
        : null
    )

    .compact()
    .value();

  const conclusionsSections = _.compact(
    _.map(conclusions.sectionsOrder, (key) => {
      return !_.isEmpty(conclusions.sections[key])
        ? {
            label: t(`labels.${key}`),
            value: <div dangerouslySetInnerHTML={{ __html: conclusions.sections[key].content }} />,
          }
        : null;
    })
  );

  if (_.isEmpty(notesRows) && _.isEmpty(conclusionsSections) && _.isEmpty(references)) return null;
  return (
    <ExpandableCard toggleRef={toggleRef} className="mt-5" title={t("labels.notes")}>
      {!_.isEmpty(conclusionsSections) && <CustomTable rows={conclusionsSections} />}
      {!_.isEmpty(notesRows) && <CustomTable rows={notesRows} />}
      {references && <References references={references} />}
    </ExpandableCard>
  );
};

export default Notes;
