import _ from "lodash";
import React, { ReactElement, useMemo } from "react";

type TCode = {
  name: string;
  code: {
    "@type": string;
    value: string;
  };
};

type TCodedValue = {
  "@id": string;
  "@type": string;
  codes: TCode[];
};

interface Props {
  values: TCodedValue[];
}

export default function CodedValues({ values }: Props): ReactElement {
  const parsedValue = useMemo(() => parseCodedValues(values), [values]);

  return <div>{parsedValue}</div>;
}

export const parseCodedValues = (values: TCodedValue[]) =>
  _.chain(values).map("codes").flatten().map("name").join(", ").value();
