import _ from "lodash";
import React, { useMemo, useReducer } from "react";
import { useTranslation } from "react-i18next";
import { useQueryState } from "react-router-use-location-state";
import { useRecommendationsFetch } from "../../lib/api";
import { FIELDS_TO_EXPORT } from "../../lib/constants";
import { useSearchQuery } from "../../lib/custom_hooks";
import Sidebar from "../Sidebar";
import ExportDialog from "../common/ExportDialog";
import Search from "../common/Search";
import RecommendationGroupsList from "../recommendation/RecommendationGroupsList";
import RecommendationsTabedList from "../recommendation/RecommendationsTabedList";

const initialGroupsState = {
  expandedGroups: [],
  expandedSubgroups: [],
};

const addOrRemoveGroup = (initial, groupName) =>
  _.includes(initial, groupName) ? _.without(initial, groupName) : initial.concat(groupName);

const groupsReducer = (state, action) => {
  const { expandedGroups, expandedSubgroups } = state;
  const { type, groupName } = action;
  switch (type) {
    case "toggleGroup":
      return {
        ...state,
        expandedGroups: addOrRemoveGroup(expandedGroups, groupName),
      };
    case "toggleSubgroup":
      return {
        ...state,
        expandedSubgroups: addOrRemoveGroup(expandedSubgroups, groupName),
      };
    default:
      return state;
  }
};

const RecommendationsPage = () => {
  const [viewTypeQuery] = useQueryState("viewType", "recommendations-by-modules");
  const [groupsState, groupsDispatch] = useReducer(groupsReducer, initialGroupsState);

  const initialSearchQuery = useSearchQuery();
  const { t } = useTranslation();

  const {
    data,
    isLoading,
    isError,
    currentFilters,
    onChangeFilter,
    onClearFilters,
    currentIntentSelected,
    onChangeIntentSelected,
  } = useRecommendationsFetch(viewTypeQuery);

  const dataToExport = useMemo(() => {
    return viewTypeQuery === "recommendations-by-modules"
      ? _.flatten(
          _.map(data.modules, (module) =>
            _.flatten(_.map(module.submodules, (submodule) => submodule.recommendations))
          )
        )
      : data && data.recommendations
      ? data
      : [];
  }, [data, viewTypeQuery]);

  return (
    <div className="bg-purple-500 overflow-hidden relative h-full">
      <div
        className="relative
          h-full w-full overflow-hidden flex flex-col-reverse sm:flex-row justify-end"
      >
        <div className="flex-grow">
          <div className="shadow flex flex-row flex-grow bg-white pb-2 mb-5 pt-10 md:pt-2">
            <div className="flex-grow w-ful max-w-screen-lg mx-auto px-2">
              <div className="flex items-center">
                <div className="flex-grow">
                  <Search
                    className="inline-block"
                    searchQuery={initialSearchQuery}
                    onSearch={(query) => onChangeFilter("searchQuery", query)}
                  />
                </div>
                <div className="ml-4">
                  {!isLoading && !_.isEmpty(dataToExport) && (
                    <ExportDialog
                      dataToExport={dataToExport}
                      options={FIELDS_TO_EXPORT}
                      parentSelector={() => document.body}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="w-full px-2 max-w-screen-lg mx-auto">
            {isError && <div>{t("errors.fetching_failed")}</div>}
            {viewTypeQuery === "recommendations-by-modules" ? (
              <RecommendationGroupsList
                isLoading={isLoading}
                groupsState={groupsState}
                groupsDispatch={groupsDispatch}
                searchQuery={initialSearchQuery}
                recommendationGroups={data.modules}
              />
            ) : (
              <RecommendationsTabedList
                isLoading={isLoading}
                onChangeTab={onChangeIntentSelected}
                currentActiveTab={currentIntentSelected}
                recommendations={data.recommendations}
              />
            )}
          </div>
        </div>
        {_.isEmpty(data) ? null : (
          <Sidebar
            isLoading={isLoading}
            filters={data.filterValues}
            currentFilters={currentFilters}
            onChangeFilter={onChangeFilter}
            onClearFilters={onClearFilters}
          />
        )}
      </div>
    </div>
  );
};

export default RecommendationsPage;
