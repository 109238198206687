import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import { certaintyValueShape } from "../../lib/data_shapes";
import CertaintyCircles from "./CertaintyCircles";

const Certainty = ({ value, signClassName }) => {
  const { t } = useTranslation();
  return (
    <div className="certainty flex flex-row items-center">
      <div>
        <CertaintyCircles value={value} signClassName={signClassName} />
      </div>
      <div className="ml-2 whitespace-no-wrap" style={{ lineHeight: "15px" }}>
        {t(`recommendation.certainty.${value}`)}
      </div>
    </div>
  );
};

Certainty.propTypes = {
  value: certaintyValueShape.isRequired,
  signClassName: PropTypes.string,
};

Certainty.defaultProps = {
  signClassName: "",
};

export default Certainty;
