import PropTypes from "prop-types";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { getRecommendationText, getRecommendationType } from "../../lib/helpers";
import Certainty from "../common/Certainty";
import Intents from "../common/Intents";
import Box from "../common/Box";
import RecommendationIcon from "../common/RecommendationIcon";

const RecommendationHeader = ({
  intents,
  recommendation,
  gradeCertaintyOfEvidence,
  gradeCertaintyOfEvidenceLabel,
  uniqueRecommendationCode,
}) => {
  const { t } = useTranslation();
  const { strength: gradeStrength, direction: recommendationDirection } = recommendation ?? {};

  const recommendationType = useMemo(() => {
    return getRecommendationType(recommendationDirection, gradeStrength);
  }, [recommendationDirection, gradeStrength]);

  const recommendationText = useMemo(() => {
    return getRecommendationText(t, recommendationDirection, gradeStrength);
  }, [t, recommendationDirection, gradeStrength]);

  return (
    <div>
      <div className="flex flex-row">
        <div className="flex-grow">
          <h1 className="text-teal-100 text-xl font-bold">{t("recommendation.title")}</h1>
          <Intents intents={intents} />
        </div>
        <div>
          <div className="flex flex-col md:flex-row">
            {recommendation.strength && (
              <Box tooltip={t("tooltips.strength")} className="mr-2">
                <div className="font-semibold">{t("recommendation.recommendation_strength")}</div>
                <div>
                  <RecommendationIcon
                    textClasses="font-normal"
                    recommendationText={recommendationText}
                    recommendationType={recommendationType}
                  />
                </div>
              </Box>
            )}
            {gradeCertaintyOfEvidence && (
              <Box tooltip={t("tooltips.certainty_of_evidence")}>
                <div className="font-semibold">
                  {t(`recommendation.${gradeCertaintyOfEvidenceLabel}`)}
                </div>
                <Certainty value={gradeCertaintyOfEvidence} />
              </Box>
            )}
          </div>
        </div>
      </div>
      <div>
        <div
          className="font-semibold mr-6"
          dangerouslySetInnerHTML={{ __html: recommendation.value }}
        />
        {uniqueRecommendationCode && (
          <div className="py-2 text-gray-600">{uniqueRecommendationCode}</div>
        )}
      </div>
    </div>
  );
};

RecommendationHeader.propTypes = {
  recommendation: PropTypes.object.isRequired,
  gradeStrength: PropTypes.string,
  gradeCertaintyOfEvidence: PropTypes.string,
  intents: PropTypes.arrayOf(PropTypes.string),
  uniqueRecommendationCode: PropTypes.string,
};

RecommendationHeader.defaultProps = {
  uniqueRecommendationCode: null,
  gradeCertaintyOfEvidence: null,
  gradeStrength: null,
  intents: [],
};

export default RecommendationHeader;
