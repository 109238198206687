import { DynamicHeightContainer } from "@evidenceprime/react-components";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { useImperativeHandle, useState, useCallback } from "react";

const ExpandableCard = ({
  children,
  className,
  contentClassName,
  contentBg,
  forwardRef,
  onTransitionEnd,
  title,
  tooltip,
  toggleRef,
}) => {
  const [expanded, setExpanded] = useState(false);
  const [scrollToSectionAfterTransisionEnded, setScrollToSectionAfterTransitionEnded] = useState(
    ""
  );

  const scrollToSection = useCallback((section) => {
    document.getElementById(section).scrollIntoView({ behavior: "smooth" });
  }, []);

  useImperativeHandle(toggleRef, () => ({
    openAndScrollTo: (section) => {
      if (!expanded) {
        setExpanded(true);
        setScrollToSectionAfterTransitionEnded(section);
      } else {
        scrollToSection(section);
      }
    },
  }));

  const _onTransitionEnd = useCallback(() => {
    if (!_.isEmpty(scrollToSectionAfterTransisionEnded)) {
      scrollToSection(scrollToSectionAfterTransisionEnded);
    }
    _.isFunction(onTransitionEnd) && onTransitionEnd();
    setScrollToSectionAfterTransitionEnded("");
  }, [scrollToSectionAfterTransisionEnded, onTransitionEnd, scrollToSection]);

  return (
    <DynamicHeightContainer
      ref={forwardRef}
      className={className}
      onTransitionEnd={_onTransitionEnd}
    >
      <div
        className={`
          bg-blue-800
          cursor-pointer
          expand-toggle
          expandable-card
          hover:opacity-75
          px-3 
          py-2
          relative
          text-white
        ${expanded ? "expanded" : "collapsed"}`}
        ref={toggleRef}
        onClick={() => setExpanded(!expanded)}
      >
        {title}
        {tooltip && (
          <span
            className="info-tooltip-icon"
            data-event="click focus"
            data-event-off="blur mouseout"
            data-tip={tooltip}
          />
        )}
      </div>
      {expanded && (
        <div className={`expandable-card__content p-5 ${contentClassName} ${contentBg}`}>
          <div>{children}</div>
          <div className="collapse-arrow">
            <button className="collapse-arrow__button" onClick={() => setExpanded(false)} />
          </div>
        </div>
      )}
    </DynamicHeightContainer>
  );
};

ExpandableCard.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  contentClassName: PropTypes.string,
  contentBg: PropTypes.string,
  onTransitionEnd: PropTypes.func,
  forwardRef: PropTypes.node,
  title: PropTypes.string.isRequired,
  tooltip: PropTypes.string,
};

ExpandableCard.defaultProps = {
  className: "",
  contentClassName: "",
  contentBg: "bg-gray-500",
  onTransitionEnd: null,
  tooltip: null,
  forwardRef: null,
};

export default ExpandableCard;
