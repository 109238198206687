import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import Header from "./components/Header";
import LargeSpinner from "./components/common/LargeSpinner";
import PageLayout from "./components/layouts/PageLayout";
import About from "./components/pages/About";
import Glossary from "./components/pages/Glossary";
import Grid from "./components/pages/Grid";
import MainPage from "./components/pages/MainPage";
import RecommendationPage from "./components/pages/RecommendationPage";
import RecommendationsPage from "./components/pages/RecommendationsPage";
import TagManager from "react-gtm-module";

const tagManagerArgs = {
  gtmId: "G-YW9NL7TCTZ",
};

if (window.location.hostname.indexOf("test") === -1) {
  TagManager.initialize(tagManagerArgs);
}

const AppComponent = () => {
  const { t } = useTranslation("");

  return (
    <div className="font-sans h-screen overflow-hidden w-screen m-0 p-0">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t("app.title")}</title>
        <link rel="icon" type="image/png" href="favicon.png" sizes="16x16" />
      </Helmet>
      <Switch>
        <Route exact path="/">
          <MainPage />
        </Route>
        <Route exact path="/recommendations">
          <Header />
          <RecommendationsPage />
        </Route>
        <Route exact path="/grid">
          <Header />
          <Grid />
        </Route>
        <Route exact path="/glossary">
          <Header />
          <PageLayout>
            <Glossary />
          </PageLayout>
        </Route>
        <Route path="/recommendation/:id">
          <Header />
          <PageLayout>
            <RecommendationPage />
          </PageLayout>
        </Route>
        <Route exact path="/about">
          <Header />
          <PageLayout>
            <About />
          </PageLayout>
        </Route>
      </Switch>
    </div>
  );
};

function App() {
  const { ready } = useTranslation("", { useSuspense: false });

  // wait until translations are loaded
  return ready ? (
    <Router>
      <AppComponent />
    </Router>
  ) : (
    <LargeSpinner />
  );
}

export default App;
