import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link, withRouter } from "react-router-dom";
import logoSmall from "../../assets/who-logo-small.svg";
import logo from "../../assets/who-logo.svg";
import HamburgerMenu from "../common/HamburgerMenu";
import Search from "../common/Search";
import { useSearchQuery } from "../../lib/custom_hooks";

const LinkToSubpage = ({ text, className, to, iconName }) => (
  <Link to={to}>
    <span
      className={`recommendation-header-link items-center shadow relative text-purple-200 text-xl bg-white ${className}`}
    >
      <span className={`link-icon link-icon--${iconName}`} />
      <span className="link-text">{text}</span>
    </span>
  </Link>
);

LinkToSubpage.propTypes = {
  text: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  className: PropTypes.string,
};

LinkToSubpage.defaultProps = {
  className: "",
};

const MainPage = ({ history, location }) => {
  const { t } = useTranslation("");
  const initialSearchQuery = useSearchQuery();

  return (
    <div className="overflow-hidden h-full relative flex flex-col">
      <header className="main-page-header relative p-2">
        <div className="flex flex-row items-center">
          {location.pathname !== "/" && (
            <Link to="/" className="mobile-back-link block sm:hidden" />
          )}

          <Link to="/">
            <img src={logo} className="h-12 tailwind-hidden sm:block" alt="logo" />
            <img src={logoSmall} className="h-8 block sm:hidden" alt="logo" />
          </Link>
          <HamburgerMenu />
        </div>
      </header>
      <div className="main-page-content mx-2 sm:mx-0 overflow-auto flex-grow">
        <div className="mx-auto w-full max-w-screen-md">
          <h1 className={`text-center py-3 mt-12 text-5xl text-yellow-500 font-semibold`}>
            {t(`app.title`)}
            <div className="text-xl">{t("app.mainPageSubtitle")}</div>
          </h1>
        </div>
        <Search
          className="block mx-auto my-12 mx-auto w-full max-w-screen-md main-page-search-container"
          inputClassName="w-full bg-white rounded-full main-page"
          searchQuery={initialSearchQuery}
          onSearch={(query) =>
            history.push(
              `/recommendations?${new URLSearchParams(`searchQuery=${query}`).toString()}`
            )
          }
        />
        <div className="mx-auto w-full max-w-screen-md my-6 px-3">
          <div className="text-gray-350 text-base text-center">{t("app.description")}</div>
        </div>
        <div className="max-w-screen-md mx-auto flex flex-row items-center my-20 justify-center lg:justify-around pb-20">
          <LinkToSubpage
            iconName="grid--no-shadow"
            className="tailwind-hidden lg:flex lg:flex-row"
            to="/grid"
            text={t(`recommendations.grid`)}
          />
          <LinkToSubpage
            iconName="list--no-shadow"
            className="flex flex-row"
            to="/recommendations"
            text={t(`recommendations.list`)}
          />
        </div>
      </div>
    </div>
  );
};

export default withRouter(MainPage);
