import _ from "lodash";
import PropTypes from "prop-types";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { getRecommendationText, getRecommendationType } from "../../lib/helpers";
import RecommendationIcon from "../common/RecommendationIcon";
import RecommendationCertainty from "../recommendation/RecommendationCertainty";
import ExportDialog from "../common/ExportDialog";
import { FIELDS_TO_EXPORT } from "../../lib/constants";

const RecommendationsComparison = ({
  recommendationsToCompare,
  highlightedRecommendation,
  highlightRecommendation,
}) => {
  const { t } = useTranslation();

  const recommendationType = useCallback((gradeStrength, recommendationDirection) => {
    return getRecommendationType(recommendationDirection, gradeStrength);
  }, []);

  const recommendationText = useCallback(
    (gradeStrength, recommendationDirection) => {
      return getRecommendationText(t, recommendationDirection, gradeStrength);
    },
    [t]
  );

  return (
    <div id="recommendations-comparison">
      <div className="pb-2 border-bottom--blue-300 mx-3 flex flex-row items-center space-between">
        <h1 className="ml-6 flex flex-grow">{t("labels.recommendations")}</h1>
        <ExportDialog options={FIELDS_TO_EXPORT} dataToExport={recommendationsToCompare} />
      </div>
      <ul className="recommendations-to-compare">
        {_.map(recommendationsToCompare, (rec) => (
          <li
            key={rec["@id"]}
            className={`flex flex-row pb-4 pt-2 px-2 mb-4 
          ${highlightedRecommendation === rec["@id"] ? "highlighted" : ""}`}
          >
            <div className="flex-grow mr-4">
              <Link
                to={`/recommendation/${rec["@id"]}`}
                className="hover:opacity-75"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div
                  className="mb-2"
                  dangerouslySetInnerHTML={{ __html: rec.recommendation }}
                ></div>
              </Link>
              <div className="flex font-raleway text-xs text-gray-450 items-center">
                <div className="flex-grow">
                  <RecommendationCertainty
                    noBox
                    gradeCertaintyOfEvidence={rec.gradeCertaintyOfEvidence}
                    gradeCertaintyOfEvidenceLabel={rec.gradeCertaintyOfEvidenceLabel}
                  />
                </div>
                <div>
                  {rec.gradeStrength && (
                    <div data-tip={t("tooltips.strength")}>
                      <div>{t("recommendation.recommendation_strength")}</div>
                      <div>
                        <RecommendationIcon
                          textClasses="font-normal"
                          recommendationText={recommendationText(
                            rec.gradeStrength,
                            rec.recommendationDirection
                          )}
                          recommendationType={recommendationType(
                            rec.gradeStrength,
                            rec.recommendationDirection
                          )}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {!_.isEmpty(rec.guidelineCitation) && (
                <div className="text-xs">
                  <div className="font-semibold mt-3">{t("labels.source_of_recommendation")}</div>
                  <div>{rec.guidelineCitation}</div>
                </div>
              )}
            </div>
            <div className="highlight-container">
              <button
                className={`btn ${highlightedRecommendation === rec["@id"] ? "highlight" : ""}`}
                onClick={() =>
                  highlightRecommendation(
                    highlightedRecommendation === rec["@id"] ? "" : rec["@id"]
                  )
                }
              >
                {highlightedRecommendation === rec["@id"]
                  ? t("labels.highlighted")
                  : t("actions.highlight")}
              </button>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

RecommendationsComparison.propTypes = {
  recommendationsToCompare: PropTypes.arrayOf(
    PropTypes.shape({
      "@id": PropTypes.string.isRequired,
      recommendation: PropTypes.string.isRequired,
      guidelineCitation: PropTypes.string,
    }).isRequired
  ).isRequired,
  highlightedRecommendation: PropTypes.string.isRequired,
  highlightRecommendation: PropTypes.func.isRequired,
};

export default RecommendationsComparison;
