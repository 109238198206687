import { map } from "lodash";
import PropTypes from "prop-types";
import React from "react";
import { interventionShape, populationShape, questionShape } from "../../lib/data_shapes";
import QuestionData from "./QuestionData";

const QuestionsData = ({
  coexistingCondition,
  evidenceProfilePagesRangeGuideline,
  evidenceProfilePagesRangePDF,
  evidenceTableLink,
  evidenceToDecisionPagesRangeGuideline,
  evidenceToDecisionPagesRangePDF,
  evidenceToDecisionUrl,
  interventions,
  populations,
  questions,
  guidelineData,
}) => {
  return (
    <div>
      {map(questions, (question) => (
        <QuestionData
          coexistingCondition={coexistingCondition}
          evidenceProfilePagesRangeGuideline={evidenceProfilePagesRangeGuideline}
          evidenceProfilePagesRangePDF={evidenceProfilePagesRangePDF}
          evidenceTableLink={evidenceTableLink}
          evidenceToDecisionPagesRangeGuideline={evidenceToDecisionPagesRangeGuideline}
          evidenceToDecisionPagesRangePDF={evidenceToDecisionPagesRangePDF}
          evidenceToDecisionUrl={evidenceToDecisionUrl}
          interventions={interventions}
          key={question["@id"]}
          populations={populations}
          question={question}
          guidelineData={guidelineData}
        />
      ))}
    </div>
  );
};

QuestionsData.propTypes = {
  additionalEvidenceTableUrl: PropTypes.arrayOf(PropTypes.string),
  interventions: PropTypes.arrayOf(interventionShape).isRequired,
  populations: PropTypes.arrayOf(populationShape).isRequired,
  questions: PropTypes.arrayOf(questionShape.isRequired),
  evidenceTableLink: PropTypes.string,
  coexistingCondition: PropTypes.string,
};

export default QuestionsData;
