import _ from "lodash";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecommendationsFetch } from "../../lib/api";
import { useSearchQuery } from "../../lib/custom_hooks";
import { generateColorMap } from "../../lib/helpers";
import Sidebar from "../Sidebar";
import InstructionModal from "../common/InstructionModal";
import Search from "../common/Search";
import RecommendationsMapTable from "../recommendationMap/RecommendationsMapTable";
import recommendationBoxThumbnail from "../../assets/recommendation-box.png";
import recommendationCounterThumbnail from "../../assets/recommendation-counter.svg";
import interventionsBarThumbnail from "../../assets/interventions-bar.svg";
import populationsBarThumbnail from "../../assets/populations-bar.svg";

// TODO use gradient array
const gradientStart = "#99CE86";
const gradientMiddle = "#F0C343";
const gradientEnd = "#FF5D73";

const THUMBNAILS = {
  interventionsBar: interventionsBarThumbnail,
  populationsBar: populationsBarThumbnail,
  recommendationBox: recommendationBoxThumbnail,
  recommendationCounter: recommendationCounterThumbnail,
};

const InstructionSection = ({ sectionId }) => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-row mb-3 last:mb-0">
      <div className="flex flex-shrink-0 p-2 text-center w-128 mr-3" style={{ width: "200px" }}>
        <img className="mx-auto" src={THUMBNAILS[sectionId]} alt="" />
      </div>
      <div>
        <div className="font-semibold text-cadth-100 mb-2">{t(`labels.${sectionId}`)}</div>
        <div>{t(`generalInstructions.${sectionId}`)}</div>
      </div>
    </div>
  );
};

const GridInstructions = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="mb-5">{t("generalInstructions.description")}</div>
      <InstructionSection sectionId="interventionsBar" />
      <InstructionSection sectionId="populationsBar" />
      <InstructionSection sectionId="recommendationCounter" />
      <InstructionSection sectionId="recommendationBox" />
    </>
  );
};

const GridHeader = ({ onChangeFilter }) => {
  const { t } = useTranslation();
  const initialSearchQuery = useSearchQuery();

  return (
    <div className="grid-page-header w-full bg-white shadow mb-6">
      <div className="flex flex-row">
        <Search
          className="inline-block ml-3 mr-6 mt-1 mb-2"
          searchQuery={initialSearchQuery}
          onSearch={(query) => onChangeFilter("searchQuery", query)}
        />
        <InstructionModal
          triggerLabel={
            <button className="ml-2 text-cadth-100 hover:opacity-50">
              {t("labels.instructions")}
            </button>
          }
        >
          <GridInstructions />
        </InstructionModal>
      </div>
    </div>
  );
};

const Grid = ({
  isLoading,
  currentIntentSelected,
  onChangeIntentSelected,
  data,
  onChangeFilter,
  currentFilters,
  onClearFilters,
}) => {
  const [heatMap, setHeatMap] = useState(false);

  const colorsCount = data.maxCount || 10;

  // TODO use gradient array
  const colorMap = useMemo(
    () => generateColorMap(gradientStart, gradientMiddle, gradientEnd, colorsCount),
    [colorsCount]
  );

  return (
    <>
      <GridHeader onChangeFilter={onChangeFilter} />
      <RecommendationsMapTable
        currentIntentSelected={currentIntentSelected}
        onChangeIntentSelected={onChangeIntentSelected}
        colorMap={colorMap}
        isLoading={isLoading}
        displayMode={heatMap ? "heatMap" : "normal"}
        grid={data}
      />
      <Sidebar
        colorsCount={colorsCount}
        currentFilters={currentFilters}
        expandable
        filters={data.filterValues}
        heatMap={heatMap}
        isLoading={isLoading}
        gridSidebar
        onChangeFilter={onChangeFilter}
        onClearFilters={onClearFilters}
        setHeatMap={setHeatMap}
      />
    </>
  );
};

const GridPage = () => {
  const { t } = useTranslation();

  const {
    currentFilters,
    currentIntentSelected,
    data,
    isError,
    isLoading,
    onChangeFilter,
    onChangeIntentSelected,
    onClearFilters,
  } = useRecommendationsFetch("grid");

  if (_.isEmpty(data) && !isLoading) return null;

  return (
    <div className="bg-purple-500 overflow-hidden page-content relative">
      {isError && <div>{t("errors.fetching_failed")}</div>}
      <Grid
        currentFilters={currentFilters}
        currentIntentSelected={currentIntentSelected}
        data={data}
        isLoading={isLoading}
        onChangeFilter={onChangeFilter}
        onChangeIntentSelected={onChangeIntentSelected}
        onClearFilters={onClearFilters}
      />
    </div>
  );
};

export default GridPage;
