import _, { chain, isEmpty, upperFirst } from "lodash";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { interventionShape, populationShape, questionShape } from "../../lib/data_shapes";
import { parsePage } from "../../lib/pages_parser";
import Card from "../common/Card";
import CustomTable from "../common/CustomTable";
import ExternalLink from "../common/ExternalLink";
import RangedLinkRow from "../recommendation/RangedLinkRow";
import CodedValues from "./CodedValues";

const AGE_ORDER = ["infant", "child", "adolescent", "adult", "unspecified"];

const mapIdsToValues = (ids, values) =>
  chain(ids)
    .map((id) => values.find((v) => v["@id"] === id["@id"]))
    .compact()
    .value();

const getAdditionalRows = (question, t) =>
  chain(["evidenceProfile", "etd", "evidenceSynthesis"])
    .map((key) => {
      if (!isEmpty(question[`${key}Reference`])) {
        return {
          label: t(`labels.${key}`),
          value: (
            <ExternalLink
              href={question[`${key}Reference`]}
              label={
                !isEmpty(question[`${key}Pages`])
                  ? t("labels.see_pages", { pages: question[`${key}Pages`] })
                  : question[`${key}Reference`]
              }
            />
          ),
        };
      }
      return null;
    })
    .compact()
    .value();

const QuestionData = ({
  coexistingCondition,
  evidenceProfilePagesRangeGuideline,
  evidenceProfilePagesRangePDF,
  evidenceTableLink,
  evidenceToDecisionPagesRangeGuideline,
  evidenceToDecisionPagesRangePDF,
  evidenceToDecisionUrl,
  interventions,
  populations,
  question,
}) => {
  const { t } = useTranslation();
  const questionPopulations = useMemo(
    () => mapIdsToValues(question.population, populations),
    [question.population, populations]
  );
  const questionInterventions = useMemo(
    () => mapIdsToValues(question.intervention, interventions),
    [question.intervention, interventions]
  );

  const ages = useMemo(
    () =>
      chain(questionPopulations)
        .map("ages")
        .flatten()
        .uniq()
        .compact()
        .sort((age1, age2) => AGE_ORDER.indexOf(age1) - AGE_ORDER.indexOf(age2))
        .map((age) => t(`recommendation.ages.${age.toLowerCase()}`))
        .join(", ")
        .value(),
    [t, questionPopulations]
  );

  const additionalRows = useMemo(() => getAdditionalRows(question, t), [question, t]);
  return (
    <Card className={`question-data bg-gray-500 my-5 shadow-none`}>
      <div className="flex flex-row">
        <CustomTable
          className="flex-grow"
          rows={[
            {
              label: t("recommendation.populations"),
              value: (
                <div>
                  <CodedValues values={questionPopulations} />
                </div>
              ),
            },
            coexistingCondition
              ? {
                  label: t("recommendation.coexisting_condition"),
                  value: <div>{t(`filters.coexistingCondition.${coexistingCondition}`)}</div>,
                }
              : null,
            {
              label: t("recommendation.age"),
              value: upperFirst(ages),
            },
            {
              label: t("recommendation.interventions"),
              value: <CodedValues values={questionInterventions} />,
            },
          ]}
        />
      </div>
      {!_.isEmpty(additionalRows) && (
        <div className="flex flex-row mt-8">
          <CustomTable className="flex-grow" rows={additionalRows} />
        </div>
      )}
      <div className="flex flex-row mt-8">
        <CustomTable
          className="flex-grow"
          rows={[
            RangedLinkRow({
              label: t("labels.evidenceProfile"),
              link: evidenceTableLink,
              pages: evidenceProfilePagesRangeGuideline,
              pdfPages: parsePage(evidenceProfilePagesRangePDF),
            }),
            RangedLinkRow({
              label: t("labels.evidenceToDecision"),
              link: evidenceToDecisionUrl,
              pages: evidenceToDecisionPagesRangeGuideline,
              pdfPages: parsePage(evidenceToDecisionPagesRangePDF),
            }),
          ]}
        />
      </div>
    </Card>
  );
};

QuestionData.propTypes = {
  population: populationShape,
  intervention: interventionShape,
  question: questionShape,
};

export default QuestionData;
