import _ from "lodash";
import React, { MouseEventHandler, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactTooltip from "react-tooltip";
import { TRecommendationType, TTab } from "../../lib/types";
import LargeSpinner from "../common/LargeSpinner";
import { RecommendationsList } from "./RecommendationGroupsList";

const TABS = [
  "all",
  "prevention",
  "screening",
  "diagnosis",
  "treatment",
  "specialNeedsAndPopulations",
  "tuberculosisAndComorbidities",
];

const MAX_LABEL_WIDTH = 30;

interface ITab {
  tab: TTab;
  currentActiveTab?: TTab;
  onChangeTab: (tab: TTab) => MouseEventHandler<HTMLDivElement> | undefined;
}

const Tab = ({ tab, currentActiveTab, onChangeTab }: ITab) => {
  const { t } = useTranslation();

  const activeTab = _.isEmpty(currentActiveTab) ? "all" : currentActiveTab;

  const getTabLabel = (key: string): string => {
    const label = t(`tabs.${key}`);
    return label.length > MAX_LABEL_WIDTH ? label.slice(0, MAX_LABEL_WIDTH) + "..." : label;
  };

  return (
    <div
      onClick={onChangeTab(tab)}
      className={`md:flex my-2 lg:my-0 lg:mx-0 flex-grow mr-1 px-3 py-3
        lg:py-0 items-center cursor-pointer moduleTab tab--${tab} ${
        activeTab === tab ? "tab--active" : ""
      }`}
    >
      <div
        className="w-full text-left lg:text-center"
        data-tip={t(`tabs.${tab}`).length > MAX_LABEL_WIDTH ? t(`tabs.${tab}`) : null}
      >
        {getTabLabel(tab)}
      </div>
    </div>
  );
};

interface ITabs {
  currentActiveTab?: TTab;
  onChangeTab: (tab: TTab) => MouseEventHandler<HTMLDivElement> | undefined;
}

const Tabs = ({ currentActiveTab, onChangeTab }: ITabs) => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(false);
  const _onChangeTab = (tab: TTab) => {
    setExpanded(false);
    return onChangeTab(tab);
  };
  return (
    <div className="flex flex-col sm:flex-row">
      <button
        className={`tab-toggle-btn block w-full text-left lg:text-center mx-2 relative md:hidden ${
          expanded ? "expanded" : "collapsed"
        }`}
        onClick={() => setExpanded(!expanded)}
      >
        {t(`tabs.${currentActiveTab || "all"}`)}
      </button>
      <div
        className={`md:flex w-full ${
          expanded
            ? "tabs block mt-8 bg-white lg:bg-transparent absolute expanded"
            : "tailwind-hidden md:block collapsed"
        }`}
      >
        {_.map(TABS, (tab: TTab) => (
          <Tab key={tab} tab={tab} currentActiveTab={currentActiveTab} onChangeTab={_onChangeTab} />
        ))}
      </div>
    </div>
  );
};

interface IRecommendationsTabedList {
  isLoading: boolean;
  recommendations: TRecommendationType[];
  currentActiveTab: TTab;
  onChangeTab: (tab: TTab) => MouseEventHandler<HTMLDivElement> | undefined;
}

const RecommendationTabbedList = ({
  currentActiveTab,
  isLoading,
  recommendations,
  onChangeTab,
}: IRecommendationsTabedList) => {
  const { t } = useTranslation();

  return (
    <div>
      <Tabs currentActiveTab={currentActiveTab} onChangeTab={onChangeTab} />
      {isLoading ? (
        <LargeSpinner />
      ) : (
        <div className="tabbed-recommendations-list">
          {!_.isEmpty(recommendations) ? (
            <RecommendationsList recommendations={recommendations || []} />
          ) : (
            <div>{t("errors.no_results")}</div>
          )}
        </div>
      )}
      <ReactTooltip
        className="tooltip-container"
        type="dark"
        effect="float"
        backgroundColor="#031B49"
      />
    </div>
  );
};
export default RecommendationTabbedList;
